import React, { FC } from 'react';

import { IListGridCollapseItem } from './interfaces';

import './styles.scss';

// list grid collapse item
const ListGridCollapseItem: FC<IListGridCollapseItem> = ({
  active,
  description,
  index,
  setActive,
  title,
}) => {
  const toggle: any = active ? null : index;

  // render
  return (
    <div
      className="list-grid-collapse-item"
      data-active={active}>
      <button
        className="list-grid-collapse-item--header"
        onClick={() => setActive(toggle)}>
        <span className="icon icon-arrow"></span>
        <p className="title">{title}</p>
      </button>
      <p className="description">{description}</p>
    </div>
  );
};

export default ListGridCollapseItem;