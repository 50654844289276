import React, { FC } from 'react';

import { IListNewsControl } from './interfaces';

import './styles.scss';

// list news control
const ListNewsControl: FC<IListNewsControl> = ({
  currentSlide = 0,
  onClick,
  slideCount = 0,
  type,
}) => {
  // render
  return (
    <button
      className={`btn list-news-control-button ${type}`}
      data-disabled={type === 'left' ? currentSlide <= 0 : currentSlide >= (slideCount - 1) || !onClick}
      onClick={onClick}>
      <span className="icon icon-arrow-strong"></span>
    </button>
  );
};

export default ListNewsControl;