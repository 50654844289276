import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { IListBlockImage } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// list block image
const ListBlockImage: FC<IListBlockImage> = ({
  value: {
    items
  }
}) => {
  return (
    <div className="list-block-image">
      <ul className="list-block-image--list">
        {items && items.map((item: any, index: number) =>
          <li className="list-block-image--item" key={index}>
            <NavLink
              className="list-block-image--item--link"
              to={item.link.to}>
                <img
                  className="image"
                  src={`${PUBLIC_URL}${item.image}`}
                  alt={item.link.name} />
                <span className="text">{item.link.name}</span>
            </NavLink>
          </li>)}
      </ul>
    </div>
  );
};

export default ListBlockImage;