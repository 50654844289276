import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import HeaderMenuList from './HeaderMenuList';

import { IHeaderMenu } from './interfaces';

import './styles.scss';

// header menu
const HeaderMenu: FC<IHeaderMenu> = ({
  items,
  open
}) => {
  // render
  return (
    <div
      className="header-menu"
      data-open={open}>
      <Link
        className="header-menu--home"
        to="/">
        <img src={`${process.env.PUBLIC_URL}/images/svg/logo.svg`} alt="OTP" />
      </Link>

      <HeaderMenuList
        items={items}
        open={open} />
    </div>
  );
};

export default HeaderMenu;