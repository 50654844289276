import React, { FC } from 'react';

import { IListGrid } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// list grid
const ListGrid: FC<IListGrid> = ({
  value: {
    items
  }
}) => {
  // render
  return (
    <div className="list-grid">
      <div className="list-grid--container">
        {items && items.map((item: any, index: number) =>
          <div
            className="list-grid--item"
            key={index}
            data-type={item.type}>
            <span className={`icon ${item.icon}`}></span>
            <p className="title">{item.title}</p>
            <p className="description">{item.description}</p>

            {item.type === 2 &&
              <img
                className="image"
                src={`${PUBLIC_URL}${item.img}`}
                alt={item.title} />}
          </div>)}
      </div>
    </div>
  );
};

export default ListGrid;