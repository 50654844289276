import React, { FC, useCallback, useContext } from 'react';

import { MainContext } from '../../providers/MainProvider';
import { PageContext } from '../../providers/PageProvider';

import PageClear from './PageClear';
import PageInterna from './PageInterna';
import PageSubMenu from './PageSubmenu';

import "./styles.scss";

// page
const Page: FC<any> = ({
  route: {
    type
  }
}) => {
  const { onLoadPage } = useContext(MainContext);
  const { data } = useContext(PageContext);

  // get type
  const getTypePage = useCallback((type: string) => {
    if (!data?.data) return false;

    switch(type) {
      case 'submenu':
        return <PageSubMenu
          value={data.data}
          onLoadPage={onLoadPage} />;
      case 'interna':
        return <PageInterna
          value={data}
          onLoadPage={onLoadPage}  />;
      case 'clear':
        return <PageClear
          value={data}
          onLoadPage={onLoadPage}  />;
      default:
        return onLoadPage(data);
    }
  }, [data, onLoadPage]);

  // render
  return (
    <div className={`page ${type}`}>
      {getTypePage(type)}
    </div>
  );
};

export default Page;