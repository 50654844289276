import React, { FC } from 'react';

import ListGridIconItem from './ListGridIconItem';

import { IListGridIcon } from './interface';

import './styles.scss';

// list grid icon
const ListGridIcon: FC<IListGridIcon> = ({
  value: {
    items
  }
}) => {
  return (
    <div className="list-grid-icon">
      <ul className="list-grid-icon--list">
        {Array.isArray(items) && items.map((item: any, index: number) =>
          <li className="list-grid-icon--item" key={index}>
            <ListGridIconItem {...item} />
          </li>)}
      </ul>
    </div>
  );
};

export default ListGridIcon;