import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import HeaderSubMenu from '../../HeaderSubmenu';

import { IHeaderMenuList } from './interfaces';

import './styles.scss';

// header menu list
const HeaderMenuList: FC<IHeaderMenuList> = ({
  items,
  open
}) => {
  return (
    <ul
      className="header-menu-list"
      data-open={open}>
      {items && items.map((item: any, index: number) =>
        <li
          className="header-menu-list--item"
          key={index}>
            <NavLink
              className="link"
              activeClassName="active"
              to={item?.to}>
              {item?.name}
            </NavLink>

            {item?.submenu &&
              <HeaderSubMenu
                items={item?.submenu} />}
          </li>)}
    </ul>
  );
};

export default HeaderMenuList;