import React, { FC } from 'react';

import Content from '../../layout/Content';

import { IBanner } from './interfaces';

import './styles.scss';

// banner
const Banner: FC<IBanner> = ({
  value: {
    icon,
    description,
    text,
    title,
    subtitle
  }
}) => {
  return (
    <div className="banner">
      <Content>
        <div className="banner--container">
          <div className="banner--description">
            <p className="text">{description}</p>
          </div>

          <div className="banner--info">
            <span className={`icon ${icon}`}></span>

            <div className="banner--info--title">
              <p className="text">{text}</p>
              <h2 className="title">{title}</h2>
              <p className="small">{subtitle}</p>
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default Banner;