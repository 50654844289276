import React, { FC } from 'react';

import ReportsTabs from './ReportsTabs';

import { IReports } from './interfaces';

import './styles.scss';

// reports
const Reports: FC<IReports> = ({
  value
}) => {
  // render
  return (
    <div className="reports">
      {Array.isArray(value) && value.map((item, index: number) =>
        <ReportsTabs
          {...item}
          key={index} />)}
    </div>
  );
};

export default Reports;