import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { IListNewsItem } from './interfaces';

import './styles.scss';

// list news item
const ListNewsItem: FC<IListNewsItem> = ({
  slug,
  description = '',
  title,
  getDate,
  image,
  date
}) => {
  // get image
  const getImage = useCallback(() => {
    if (image instanceof Object && image.url !== '') {
      return image.url;
    }

    return `${process.env.PUBLIC_URL}/images/placeholder-news.jpg`;
  }, [image]);

  // render
  return (
    <div
      className="list-news-item">
      <Link
        className="link"
        to={slug}>
        <div className="list-news-item--date">
          <span className="icon icon-facebook"></span>

          {date &&
            <p className="paragraph">{getDate(date, 'd-MM-yyyy')}</p>}
        </div>

        <div className="list-news-item--image">
          <img
            alt={title}
            src={getImage()} />
        </div>
        <div className="list-news-item--info">
          <p className="description" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </Link>
    </div>
  );
};

export default ListNewsItem;