import React, { FC } from 'react';

import { IPaginationControl } from './interfaces';

import './styles.scss';

// pagination control
const PaginationControl: FC<IPaginationControl> = ({
  disable,
  onPrevNext,
  type,
}) => {
  return (
    <button
      className="pagination-control"
      data-type={type}
      data-disabled={disable}
      onClick={() => onPrevNext(type)}>
      <span className="icon icon-arrow"></span>
      <span className="text">{type === 'prev' ? 'anterior' : 'próximo'}</span>
    </button>
  );
};

export default PaginationControl;