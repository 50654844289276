import React, { FC, useRef } from 'react';

import UseClickOutSide from '../../../../hooks/useClickOutSide';

import { ITabsItemModal } from './interfaces';

import './styles.scss';

const TabsItemModal: FC<ITabsItemModal> = ({
  open,
  setOpen
}) => {
  const container: any = useRef<any>(null);

  // click out
  UseClickOutSide(container, () => setOpen(null));
  
  // render
  return (
    <div className="tabs-item-modal">
      <div ref={container}>
        <img src={open.url} alt={open.title} />
      </div>
    </div>
  );
};

export default TabsItemModal;