import React, { FC, useCallback } from 'react';
import { useGesture } from 'react-use-gesture';
import clamp from 'lodash/clamp';

import SliderBase from '../../Slider/Base';

import WebdoorItem from '../WebdoorItem';

import './styles.scss';
import { IWebdoorContent } from './interfaces';

// webdoor content
const WebdoorContent: FC<IWebdoorContent> = ({
  current,
  items,
  direction,
  last,
  onPrevNext,
  setCurrent
}) => {
  // drag on gesture
  const dragOnGesture = useCallback(({
    down,
    movement: [mx],
    direction: [xDir],
    distance,
    cancel
  }: any) => {
    if (down && distance > 15) {
      const value = clamp(current + (xDir > 0 ? 1 : -1), 0, items.length - 1);
      cancel(setCurrent(value));
    }
  }, [ current, items, setCurrent ]);

  // drag
  const drag = useGesture({
    onDrag: dragOnGesture
  });

  // render
  return (
    <div className="webdoor-content">
      {items && items.map((item: any, index: number) => (
        <WebdoorItem
          active={current === index}
          drag={drag}
          dir={direction}
          item={item}
          onPrevNext={onPrevNext}
          last={last === index}
          key={index} />))}
    </div>
  );
};

export default SliderBase(WebdoorContent);