import React, { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import InputForm from '../../Form/Input/InputForm';
import InputGroup from '../../Form/Input/InputGroup';

import useForm from '../../../hooks/useForm';

import { IFormContact } from './interfaces';

import './styles.scss';

// url
const { REACT_APP_CONTACT_FORM, REACT_APP_GOOGLE_CAPTCHA }: any = process.env;

// contact form
const FormContact: FC<IFormContact> = () => {
  const recaptchaRef = useRef<any>(null);

  const [ error, setError ] = useState<boolean>(false);
  const [ open, setOpen ] = useState<boolean>(false);

  const { typesForm } = useForm();
  
  // initial
  const initialValues: any = {
    recaptcha: '',
    aceita_receber_comunicados: false,
    nome: '',
    email: '',
    telefone: '',
    assunto: '',
    empresa: '',
    mensagem: ''
  };

  // render
  return (
    <div
      className="form-contact"
      data-open={open}>
      <div
        className="form-contact--title">
        <span className="icon icon-msm"></span>
        <h2 className="title">Envie uma mensagem</h2>
        <p className="subtitle">* campos obrigatórios</p>

        <button
          className="btn"
          data-open={open}
          onClick={() => setOpen(!open)}>
          <span className="icon icon-arrow"></span>
        </button>
      </div>
      
      <Formik
        // init
        initialValues={initialValues}
        initialStatus={{ success: false }}
        // validate
        validate={(values: any) => {
          const errors: any = typesForm({ ...values }, 'contact');
          setError(Object.keys(errors).length > 0);

          return errors;
        }}
        // on submit
        onSubmit={(values, {
          setSubmitting,
          setStatus,
          resetForm
        }) => {
          setStatus({ success: false });

          const params: any = new FormData();

          for (let value in values) {
            params.append(value, values[value]);
          }

          params.append('g-recaptcha-response', recaptchaRef?.current?.getValue());

          axios(`${REACT_APP_CONTACT_FORM}/index.php`, {
            method: 'POST',
            data: params
          }).then(e => {
            if (e.data.error === false) {
              resetForm(initialValues);
              setStatus({ success: true });
            } else {
              setStatus({ error: true });
            }

            return e;
          }).catch(e => setStatus({ error: true }));

          setSubmitting(false);
        }}>
        {({
          values,
          errors,
          status,
          isSubmitting,
          setFieldValue,
          handleSubmit,
          handleChange,
          handleBlur
        }) => (
          <>
            {(status.error === false || status.success === false) &&
              <form
                onSubmit={handleSubmit}
                className="form-contact--form"
                autoComplete="off"
                data-submitting={isSubmitting}>
                <InputForm
                  label="Nome"
                  name="nome"
                  type="text"
                  error={errors.nome}
                  empty={!values?.nome}
                  required={true} />

                <InputForm
                  label="Email"
                  name="email"
                  type="text"
                  error={errors.email}
                  empty={!values?.email}
                  required={true} />

                <InputForm
                  label="Telefone"
                  name="telefone"
                  type="phone"
                  value={values?.telefone}
                  error={errors.telefone}
                  empty={!values?.telefone}
                  required={true}
                  onChange={handleChange}
                  onBlur={handleBlur} />

                <InputForm 
                  label="Assunto"
                  name="assunto"
                  type="text"
                  error={errors.assunto}
                  empty={!values?.assunto}
                  required={true} />

                <InputForm
                  label="Empresa"
                  name="empresa"
                  type="text"
                  error={errors.empresa}
                  empty={!values?.empresa}
                  required={true} />

                <InputGroup>
                  <InputForm
                    empty={values?.mensagem}
                    label="Mensagem"
                    name="mensagem"
                    type="textarea"
                    error={errors.mensagem}
                    required={true} />
                </InputGroup>

                <InputGroup>
                  <InputForm
                    name="aceita_receber_comunicados"
                    required={true}
                    type="check" />
                  <p className="check-text">Estou ciente da finalidade da utilização dos meus dados pessoais fornecidos à OTP S.A. e concordo com as diretrizes de tratamento de dados descritas no 
                    <Link to="/privacidade-de-dados">Aviso de Privacidade</Link> desse site.</p>
                </InputGroup>

                <div className="form-contact--footer">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={REACT_APP_GOOGLE_CAPTCHA}
                    onChange={(value: any) => setFieldValue('recaptcha', value)} />
                </div>

                {!status.success &&
                  <div className="form-contact--footer">
                    <button
                      className="btn"
                      type="submit"
                      disabled={isSubmitting || recaptchaRef?.current?.getValue() === '' || !values.aceita_receber_comunicados}>Enviar</button>
                  </div>}
              </form>}

              {status.success === true && <h2 className="title">Mensagem enviada com sucesso</h2>}
              {(status.error === true || Object.keys(error).length > 0) && <h2 className="title">Por favor, tente mais tarde!!!</h2>}
          </>)}
      </Formik>
    </div>
  );
};

export default FormContact;