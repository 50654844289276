import React, { FC } from 'react';

import AddressListCard from '../AddressListCard';
import SubTitle from '../../Typograph/SubTitle';

import './styles.scss';

// address list item
const AddressListItem: FC<any> = ({
  items,
  title
}) => {
  return (
    <div className="address-list-item">
      <SubTitle text={title} />
      
      <ul className="address-list-item--list">
        {Array.isArray(items) && items.map((item: any, index: number) =>
          <li className="address-list-item--list--item" key={index}>
            <AddressListCard {...item} />
          </li>)}
      </ul>
    </div>
  );
};

export default AddressListItem;