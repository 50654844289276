import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

const { PUBLIC_URL } = process.env; // env

// hight lights item
const HighlightsItem: FC<any> = ({
  image,
  title,
  to
}) => {
  // render
  return (
    <div className="highlights-item">
      <img
        className="highlights-item--image"
        src={`${PUBLIC_URL}${image.url}`}
        alt={title} />

      <Link
        className="highlights-item--link"
        to={to}>
        <span className="text">{title}</span>
      </Link>
    </div>
  );
};

export default HighlightsItem;