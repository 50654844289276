import React, { FC } from 'react';

import Content from '../../../layout/Content';
import Submenu from '../../Submenu';
import WebdoorInternal from '../../WebdoorInternal';

import { IPageSubmenu } from './interfaces';

import './styles.scss';

// page sub menu
const PageSubMenu: FC<IPageSubmenu> = ({
  value,
  onLoadPage
}) => {
  // render
  return (
    <>
      <WebdoorInternal value={{ ...value?.webdoor }} />

      {value?.data &&
        <div className="page-submenu">
          <Content>
            <div className="page-submenu--main">
              <Submenu
                items={value?.submenu} />

              <div className="page-submenu--container">
                {onLoadPage(value)}
              </div>
            </div>    
          </Content>
        </div>}
    </>
  );
};

export default PageSubMenu;