import React, { FC } from 'react';

import NewsItemInfo from './NewsItemInfo';

import { INewsItem } from './interfaces';

import './styles.scss';

// news item
const NewsItem: FC<INewsItem> = ({
  date,
  intro = '',
  image,
  slug,
  title,
}) => {
  // render
  return (
    <div
      className="news-item">
      <div className="news-item--image">
        <img
          alt={title}
          src={image?.url || `${process.env.PUBLIC_URL}/images/placeholder-news.png`} />
      </div>

      <div className="news-item--content">
        <NewsItemInfo
          intro={intro}
          date={date}
          slug={slug}
          title={title} />
      </div>
    </div>
  );
};

export default NewsItem;