import React, { FC } from 'react';

import NewsItem from '../NewsItem';

import { INewsContent } from './interfaces';

import './styles.scss';

// news content
const NewsContent: FC<INewsContent> = ({
  data
}) => {
  // render
  return (
    <div
      className="news-content">
      <div
        className="news-content--container">
        {data && data?.map((item: any, index: number) =>
          <NewsItem
            {...item}
            key={index} />)}
      </div>
    </div>
  );
};

export default NewsContent;