import React, { FC } from 'react';

import TabsItem from '../TabsItem';

import { ITabsBody } from './interfaces';

import './styles.scss';

const { REACT_APP_PAGINATION_NEWS } = process.env;

// tabs
const TabsBody: FC<ITabsBody> = ({
  current,
  filter,
  value,
}) => {
  // render
  return (
    <div className="tabs-body">
      <ul className="tabs-body--list">
        {Array.isArray(value) && value.map((item: any, index: number) =>
          <li
            className="tabs-body--item"
            data-active={current === index}
            key={index}>
            <TabsItem
              {...item}
              items={item.items.filter((item: any) => (!filter.value || filter.value === item.category))}
              filter={filter}
              current={current}
              perPage={REACT_APP_PAGINATION_NEWS} />
          </li>)}
      </ul>
    </div>
  );
};

export default TabsBody;