import React, { FC, useCallback } from 'react';
import { parse, format } from 'date-fns';

import { INewsItemInfo } from './interfaces';

import './styles.scss';
import { Link } from 'react-router-dom';

// news item
const NewsItemInfo: FC<INewsItemInfo> = ({
  intro,
  date,
  slug,
  title
}) => {
  const dataFormat = parse(format(new Date(date), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());

  // get date
  const getDate = useCallback(() => 
    format(dataFormat, 'dd/MM/yyyy'), [ dataFormat ]);

  // render
  return (
    <div
      className="news-item-info">
      <div className="news-item-info--year">
        <p className="text">{getDate()}</p>
      </div>
      <div className="news-item-info--description">
        <p className="title">{title}</p>
        <p className="description" dangerouslySetInnerHTML={{ __html: intro }} />
      
        <Link className="link" to={slug}>
          <span className="icon icon-plus"></span>
          <span className="text">saiba mais</span>
        </Link>
      </div>
    </div>
  );
};

export default NewsItemInfo;