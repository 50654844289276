import React, { FC } from 'react';

import { IListGridIconItem } from './interfaces';

import './styles.scss';

// list grid icon item
const ListGridIconItem: FC<IListGridIconItem> = ({
  icon,
  title,
  description
}) => {
  return (
    <div className="list-grid-icon-item">
      <div className="list-grid-icon-item--header">
        <span className={`icon ${icon}`}></span>
        <p className="title">{title}</p>
      </div>
      <p className="list-grid-icon-item--description">{description}</p>
    </div>
  );
};

export default ListGridIconItem;