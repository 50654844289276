import React, { FC } from 'react';

import { IGalleryVideosControls } from './interfaces';

import './styles.scss';

// gallery videos controls
const GalleryVideosControls: FC<IGalleryVideosControls> = ({
  onPrevNext
}) => {
  // render
  return (
    <div className="gallery-videos-controls">
      <button className="btn prev" onClick={() => onPrevNext('prev')}>
        <span className="icon icon-arrow-strong"></span>
      </button>

      <button className="btn next" onClick={() => onPrevNext('next')}>
        <span className="icon icon-arrow-strong"></span>
      </button>
    </div>
  );
};

export default GalleryVideosControls;