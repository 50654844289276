import useSWR from "swr";
import axios from "axios";

const { REACT_APP_BASE_API } = process.env; // envs

// fetcher
const fetcher = (url: string) => axios.get(url).then(res => res.data);

// use load
function useLoad(url: string) {
  const { data, mutate, error } = useSWR(`${REACT_APP_BASE_API}/${url}`, fetcher);

  const loading = !data && !error;

  return {
    loading,
    error,
    data,
    mutate
  };
}

export default useLoad;