import React, { FC } from 'react';

import ListGridFileItem from './ListGridFileItem';
import ListGridTitle from './ListGridTitle';

import { IListGridFile } from './interfaces';

import './styles.scss';

// list grid file
const ListGridFile: FC<IListGridFile> = ({
  value: {
    items
  }
}) => {
  return (
    <div className="list-grid-file">
      <ul className="list-grid-file--list">
        {items && items.map((item: any, index: number) => 
          <li className="list-grid-file--item" key={index}>
            {item.type === 1 && <ListGridFileItem {...item} />}
            {item.type === 2 && <ListGridTitle {...item} />}
          </li>)}
      </ul>
    </div>
  );
};

export default ListGridFile;