import axios from 'axios';
import React, { FC, useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { IGalleryVideosItem } from './interfaces';

import './styles.scss';

// gallery videos item
const GalleryVideosItem: FC<IGalleryVideosItem> = ({
  active,
  dir,
  drag,
  item: {
    url = ''
  },
  next,
  prev,
}) => {
  const [pause, setPause] = useState<boolean>(false);
  const [videoBg, setVideoBg] = useState<any>({});

  // on drag
  const onDrag = useCallback(() => {
    if (typeof drag === 'function') return drag();

    return {};
  }, [drag]);

  // get data
  const getData = useCallback(async (url: string) => {
    if (!url) return false;

    const origin = encodeURI(window.location.origin);

    return await axios.get(`https://www.youtube.com/oembed?url=${url}&format=json&origin=${origin}`)
      .then(({ data }) => data);
  }, []);

  // use effect
  useEffect(() => {
    getData(url).then((data) => setVideoBg(data));

    if (active === false) {
      setPause(false);
    }
  }, [active, url, setVideoBg, getData]);

  // render
  return (
    <div
      {...onDrag()}
      className="gallery-videos-item"
      data-active={active}
      data-next={next}
      data-prev={prev}
      data-dir={dir}>
      <div
        className="gallery-videos-item--bg"
        data-active={pause}
        onClick={() => setPause(!pause)}>
        <img
          className="gallery-videos-item--img"
          src={videoBg?.thumbnail_url}
          alt={videoBg?.title} />

        <button
          className="gallery-videos-item--play"
          onClick={() => setPause(!pause)}>
          <span className="icon icon-play"></span>
        </button>
      </div>

      <div className="gallery-videos-item--content">
        <div className="gallery-videos-item--title">
          <p className="text">{videoBg?.title}</p>
        </div>

        <ReactPlayer
          className="gallery-videos-item--video"
          height={480}
          width={854}
          playing={pause}
          url={url}
          onEnded={() => setPause(false)} />
      </div>
    </div>
  );
};

export default GalleryVideosItem;