import React, { FC } from 'react';

import Content from '../../layout/Content';
import HighlightsItem from './HighlightsItem';

import './styles.scss';

// high lights
const Highlights: FC<any> = ({
  value
}) => {
  // render
  return (
    <div className="highlights">
      <Content>
        <ul className="highlights--list">
          {Array.isArray(value.items) 
            && value.items.map((item: any, index: number) =>
            <li
              className="highlights--item"
              key={index}>
              <HighlightsItem {...item} />
            </li>)}
        </ul>
      </Content>
    </div>
  );
};

export default Highlights;