import React, { FC } from 'react';

import Content from '../../layout/Content';

import { INewDetails } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// new details
const NewDetails: FC<INewDetails> = ({
  value: {
    title,
    description,
    image
  }
}) => {
  return (
    <div className="new-details">
      <Content>
        <p className="new-details--title">{title}</p>

        <div className="new-details--container">
          <img src={`${PUBLIC_URL}${image}`} alt={title} />
          <p className="description">{description}</p>
        </div>
      </Content>
    </div>
  );
};

export default NewDetails;