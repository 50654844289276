import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { IListGridLinksItem } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// list grid links item
const ListGridLinksItem: FC<IListGridLinksItem> = ({
  image,
  subtext,
  text,
  type,
  url
}) => {
  return (
    <div className="list-grid-links-item">
      <img src={`${PUBLIC_URL}${image}`} alt={text} />

      {type === 1 && <Link
        className="link"
        to={url}>
        <span className="text">{text}</span>
        <span className="icon icon-arrow"></span>
      </Link>}

      {type === 2 && <a
        className="link"
        href={url}
        download>
        <span className="text">{text}</span>
        <span className="subtext">{subtext}</span>
        <span className="icon icon-down"></span>
      </a>}

      {type === 3 && <a
        className="link"
        href={url}>
        <span className="text">{text}</span>
        <span className="subtext">{subtext}</span>
        <span className="icon icon-down"></span>
      </a>}

      {type === 4 && <a
        className="link"
        href={url}
        target="_blank"
        rel="noreferrer">
        <span className="text">{text}</span>
        <span className="icon icon-arrow"></span>
      </a>}

      {type === 5 && <a
        className="link"
        href={url}
        target="_blank"
        rel="noreferrer">
        <span className="text">{text}</span>
        <span className="icon icon-arrow"></span>
      </a>}
    </div>
  );
};

export default ListGridLinksItem;