import React, { createContext, FC } from 'react';

import usePage from '../../hooks/usePage';

import { IPageContext, IPageProvider } from './interfaces';

// page context
const PageContext = createContext({} as IPageContext);

// page provider
const PageProvider: FC<IPageProvider> = ({
  children,
  url = '/',
}) => {
  const { data, error } = usePage(url);

  // render
  return (
    <PageContext.Provider
      value={{
        data,
        error
      }}>
        {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider };
export default PageProvider;