import React, { FC } from 'react';

import Select from '../../Form/Select';

import { ITabsFilter } from './interfaces';

import './styles.scss';

// tabs filter
const TabsFilter: FC<ITabsFilter> = ({
  items,
  filter,
  setFilter
}) => {
  // render
  return (
    <div className="tabs-filter">
      <label className="label">Empresa</label>
      <Select
        defaultText="Selecione"
        handleChange={setFilter}
        selectedOption={filter}
        options={items} />
    </div>
  );
};

export default TabsFilter;