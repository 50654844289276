import React, { FC } from 'react';

import GalleryVideosContent from './GalleryVideosContent';

import { IGalleryVideos } from './interfaces';

import './styles.scss';

// gallery videos
const GalleryVideos: FC<IGalleryVideos> = ({
  value
}) => {
  // render
  return (
    <div className="gallery-videos">
      <GalleryVideosContent {...value} />
    </div>
  );
};

export default GalleryVideos;