import React, { FC, useCallback } from 'react';
import { parse, format } from 'date-fns';

import ListNewsContent from './ListNewsContent';

import NewsProvider from '../../providers/NewsProvider';

import { IListNews } from './interfaces';

import './styles.scss';

// list news
const ListNews: FC<IListNews> = ({
  value: {
    length
  }
}) => {
  // get date
  const getDate = useCallback((date: string, form: string) => {
    const value = format(parse(date, 'yyyy-MM-dd', new Date()), form);

    return value;
  }, []);

  // render
  return (
    <NewsProvider>
      <div
        className="list-news">
        <ListNewsContent getDate={getDate} limit={length} />
      </div>
    </NewsProvider>
  );
};

export default ListNews;