import React, { FC, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import Content from '../Content';
import HeaderButtonMobile from './HeaderButtonMobile';
import HeaderMenu from './HeaderMenu';

import { MainContext } from '../../providers/MainProvider';

import { IHeader } from './interfaces';

import './styles.scss';

// header
const Header: FC<IHeader> = ({
  location
}) => {
  const { data } = useContext(MainContext);
  const [ openMobile, setOpenMobile ] = useState<boolean>(true);

  // use effect
  useEffect(() => {
    setOpenMobile(true);
  }, [ location ]);

  // render
  return (
    <header className="header">
      <Content>
        <HeaderMenu
          open={openMobile}
          items={data?.header} />

        <HeaderButtonMobile
          open={openMobile}
          setOpen={setOpenMobile} />
      </Content>
    </header>
  );
};

export default withRouter(Header);