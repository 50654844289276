import React, { FC } from 'react';
import TabsFilter from '../TabsFilter';

import { ITabsHeader } from './interfaces';

import './styles.scss';

// tabs header
const TabsHeader: FC<ITabsHeader> = ({
  current,
  items,
  filter,
  categories,
  setCurrent,
  setFilter
}) => {
  // render
  return (
    <div className="tabs-header">
      <ul className="tabs-header--list">
        {Array.isArray(items) && items.map((item: any, index: number) =>
          <li className="tabs-header--item" key={index}>
            <button
              className="btn"
              data-active={current === index}
              onClick={() => setCurrent(index)}>{item.title}</button>
          </li>)}
      </ul>

      <TabsFilter
        items={categories}
        filter={filter}
        setFilter={setFilter} />
    </div>
  );
};

export default TabsHeader;