import React, { FC } from 'react';

import NewsContent from './NewsContent';
import usePage from '../../hooks/usePage';

import { INews } from './interfaces';

import './styles.scss';

// news
const News: FC<INews> = () => {
  const { data } = usePage("/news.json");

  // render
  return (
    <div
      className="news">
      <NewsContent
        data={data} />

      <div
        className="news--loader"
        data-type="loader">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default News;