import React, { FC } from 'react';

import { IContent } from './interfaces';

import './styles.scss';

// content
const Content: FC<IContent> = ({
  children
}) => {
  return (
    <div className="content">
      <div className="content--body">
        {children}
      </div>
    </div>
  );
};

export default Content;