import React, { FC, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import PaginationArray from '../../Pagination/PaginationArray';
import PaginationFooter from '../../Pagination/PaginationFooter';
import TabsItemModal from './TabsItemModal';

import { ITabsItem } from './interfaces';

import './styles.scss';

// tabs item
const TabsItem: FC<ITabsItem> = ({
  currentPage,
  getData,
  items,
  perPage,
  setPage
}) => {
  const [ open, setOpen ] = useState<any>(null);

  // modal
  const modal: any = document.getElementById('search');
  const total: any = Math.ceil((items.length - 1) / perPage);

  // on change
  const onChange = useCallback((dir: string) => {
    if (dir === 'prev') {
      setPage((currentPage - 1) < 0 ? 0 : (currentPage - 1), total);
    } else {
      setPage((currentPage + 1) >= total ? total - 1 : (currentPage + 1), total);
    }
  }, [ currentPage, total, setPage ]);

  // use effect
  useEffect(() => {
    if (items) {
      setPage(0);
    }
  }, [ items, setPage ]);

  // render
  return (
    <div className="tabs-item">
      <ul className="tabs-item--list">
        {Array.isArray(items) && getData(items, currentPage + 1).map((item: any, index: number) =>
          <li className="tabs-item--item" key={index}>
            <button className="btn" onClick={() => setOpen(item)}>
              <img className="image" src={item.url} alt={item.title} />
              <p className="title">{item.title}</p>
            </button>
          </li>)}
      </ul>

      <PaginationFooter
        current={currentPage}
        onPrevNext={onChange}
        total={total}
        setPage={setPage} />

      {open instanceof Object && createPortal(
        <TabsItemModal open={open} setOpen={setOpen} />, modal)}
    </div>
  );
};

export default PaginationArray(TabsItem);