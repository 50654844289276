import React, { FC, useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import Content from '../../layout/Content';
import Intro from '../Intro';
import SubTitle from '../Typograph/SubTitle';
import WebdoorInternal from '../WebdoorInternal';

import usePage from '../../hooks/usePage';

import { IPageNew } from './interfaces';

// page new
const PageNew: FC<IPageNew> = ({
  location
}) => {
  const [ newData, setNewData ] = useState<any>(null);
  const { data } = usePage(`/news.json`);

  const webdoor: any = {
    "item": {
      "image": "/images/dji_0061.jpg",
      "title": "Test"
    },
    "subtitle": "Comunicação",
    "title": "Notícias",
    "type": "2"
  };

  // use effect
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const value = data.filter((item: any) => item.slug === location.pathname)[0];
      setNewData(value);
    }
  }, [ data, setNewData, location ]);

  // render
  return (
    <div className="page page-news">
      {data instanceof Object && <>
        <WebdoorInternal value={webdoor} />

        <div className="page-interna">
          <Content>
            {newData && <>
              <SubTitle text={newData.title} type={2} />
              <Intro value={{ text: newData.description, image: newData.image.url }} />
            </>}
          </Content>
        </div>
      </>}
    </div>
  );
};

export default withRouter(PageNew);