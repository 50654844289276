import React, { FC } from 'react';

import Content from '../Content';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// footer
const Footer: FC<any> = () => {
  // render
  return (
    <footer className="footer">
      <Content>
        <div className="footer--container">
          <div className="footer--company">
            <a href="/privacidade-de-dados">Aviso de Privacidade</a>
          </div>

          <div className="footer--copy">
            <p>Copyright © 2024 - Todos os direitos reservados à OTP</p>
            <img src={`${PUBLIC_URL}/images/logo-sirius.png`} alt="Sirius" />
          </div>
          </div>
      </Content>
    </footer>
  );
};

export default Footer;