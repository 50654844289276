import React, { FC } from 'react';

import { IImage } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// image
const Image: FC<IImage> = ({
  value: {
    align,
    image,
    title,
  }
}) => {
  return (
    <div className="image-component" data-type={align}>
      <img src={`${PUBLIC_URL}${image}`} alt={title} />
    </div>
  );
};

export default Image;