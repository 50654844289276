import React, { FC } from 'react';

import { IListBlock } from './interfaces';

import ListBlockItem from './ListBlockItem';

import './styles.scss';

// list block
const ListBlock: FC<IListBlock> = ({
  value: {
    items,
    title
  }
}) => {
  return (
    <div className="list-block">
      <p className="list-block--title">{title}</p>

      {Array.isArray(items) && items.map((item: any, index: number) =>
        <ListBlockItem {...item} index={index + 1} key={index} />)}
    </div>
  );
};

export default ListBlock;