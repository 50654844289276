import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import usePage from '../../../hooks/usePage';

import SubTitle from '../../Typograph/SubTitle';
import NewsItemMin from '../NewsItemMin';

import './styles.scss';

// news lists
const NewsList: FC<any> = () => {
  const { data } = usePage("/news.json");

  // render
  return (
    <div className="news-list">
      <SubTitle text="Notícias" />

      <ul className="news-list--list">
        {Array.isArray(data) && data.map((item: any, index: number) =>
          <li className="news-list--list--item" key={index}>
            <NewsItemMin {...item} />
          </li>)}
      </ul>

      <Link className="link" to="/comunicacao/noticias">ver mais</Link>
    </div>
  );
};

export default NewsList;