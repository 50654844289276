import React, { FC } from 'react';

import { IHeaderButtonMobile } from './interfaces';

import './styles.scss';

// header button mobile
const HeaderButtonMobile: FC<IHeaderButtonMobile> = ({
  open,
  setOpen
}) => {
  return (
    <button
      className="header-button-mobile"
      data-open={open}
      onClick={() => setOpen(!open)}>
      <span className="icon icon-times"></span>
      <span className="icon icon-burguer"></span>
    </button>
  );
};

export default HeaderButtonMobile;