import React, { FC } from 'react';

import { IIntro } from './interfaces';

import './styles.scss';

// intro
const Intro: FC<IIntro> = ({
  value: {
    logo,
    label,
    image,
    text
  }
}) => {
  // render
  return (
    <div className="intro">
      {logo && <div className="intro--logo">
        <img src={logo} alt="logo" />
      </div>}

      <div className="intro--info">
        <div className="intro--info--image">
          <img src={image} alt="intro" />
          <span className="label">{label}</span>
        </div>
        
        <p className="text">{text}</p>
      </div>
    </div>
  );
};

export default Intro;