import React, { FC } from 'react';

import Content from '../../../layout/Content';

import { IPageClear } from './interfaces';

import './styles.scss';

// page interna
const PageClear: FC<IPageClear> = ({
  value,
  onLoadPage
}) => {
  return (
    <>
      {value?.data &&
        <div className="page-interna clear">
          <Content>
            {onLoadPage(value)}
          </Content>
        </div>}
    </>
  );
};

export default PageClear;