import React, { FC } from 'react';

import { IListGridTitle } from './interfaces';

import './styles.scss';

// list grid title
const ListGridTitle: FC<IListGridTitle> = ({
  url,
  icon,
  name,
  title
}) => {
  return (
    <div className="list-grid-title">
      <span className={`icon ${icon}`}></span>
      <p className="title">{title}</p>

      <a href={url} className="name">{name}</a>
    </div>
  );
};

export default ListGridTitle;