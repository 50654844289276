import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { IListIcons } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// list icons
const ListIcons: FC<IListIcons> = ({
  value: {
    title,
    items
  }
}) => {
  return (
    <div className="list-icons">
      <p className="list-icons--title">{title}</p>

      <ul className="list-icons--list">
        {items && items.map((item: any, index: number) =>
          <li className="list-icons--list--item" key={index}>
            <Link className="link" to={item?.to}>
              <img src={`${PUBLIC_URL}${item.image}`} alt={item.title} />
            </Link>
          </li>)}
      </ul>
    </div>
  );
};

export default ListIcons;