import React, { FC, useState } from 'react';

import ReportsItem from '../ReportsItem';

import { IReportsTabs } from './interfaces';

import './styles.scss';

// reports tabs
const ReportsTabs: FC<IReportsTabs> = ({
  active,
  report,
  others,
}) => {
  const [ state, setState ] = useState<boolean>(false);

  // render
  return (
    <div
      className="reports-tabs"
      data-active={active}>
      <div className="reports-tabs--controls">
        <button
          className="tab"
          data-active={!state}
          onClick={() => setState(false)}>relatório anual</button>
        <button
          className="tab"
          data-active={state}
          onClick={() => setState(true)}>outros relatórios</button>
      </div>
      
      <div
        className="reports-tabs--report"
        data-active={!state}>
        <p className="description">{report.description}</p>
        <ReportsItem {...report} />
      </div>

      <div
        className="reports-tabs--others"
        data-active={state}>
        {others && others.map((item: any, index: number) => 
          <ReportsItem {...item} type={2} key={index} />)}
      </div>
    </div>
  );
};

export default ReportsTabs;