// use WindowSize
function useForm() {
  // check value
  const checkValue = (value: string, type: string) => {
    let regex: any = null;

    switch (type) {
      case 'email':
        regex = new RegExp('^[a-z0-9]+(\\.[_a-z0-9]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,15})$', 'i');
      break;
      case 'cnpj':
        regex = new RegExp('[0-9]{2}\\.?[0-9]{3}\\.?[0-9]{3}\\/?[0-9]{4}\\-?[0-9]{2}', 'g');
      break;
      case 'number':
        regex = new RegExp('^[0-9]+$');
      break;
      case 'money':
        regex = new RegExp('^(\\$?(:?\\d+,?)+(?:.?\\d+)?)$', 'g');
      break;
      case 'text':
        regex = new RegExp('^[A-zÀ-ú\\s]+$', 'g');
      break;
      case 'phone':
        regex = new RegExp('(\\(?\\d{2}\\)?\\s)?(\\d{4,5}\\-\\d{4})' ,'g');
      break;
      case 'textarea':
        regex = new RegExp('^.{6,600}$');
      break;
      default:
      case 'normal':
        return true;
    }
    
    return regex.test(value);
  };

  // number check
  const checkNumber = (value: string) => {
    return Number(value.toString().replace(/[^0-9\\.]+/g, ''));
  };

  // types
  const typesForm = (values: any, form: string) => {
    switch (form) {
      case 'contact':
        let errors: any = {};
        const {
          nome,
          email,
          telefone,
          assunto,
          empresa,
          mensagem }: any = values;

        if (nome !== '' && !checkValue(nome, 'text'))
          errors.nome = true;

        if (email !== '' && !checkValue(email, 'email'))
          errors.email = true;

        if (assunto !== '' && !checkValue(assunto, 'assunto'))
          errors.assunto = true;

        if (telefone !== '' && !checkValue(telefone, 'phone'))
          errors.telefone = true;

        if (empresa !== '' && !checkValue(empresa, 'text'))
          errors.empresa = true;

        if (mensagem === '')
          errors.mensagem = true;

        return errors;
      default:
        break;
    }
  };

  return {
    checkValue,
    checkNumber,
    typesForm
  };
}

export default useForm;