import React, { FC } from 'react';

import { IListNumbers } from './interfaces';

import './styles.scss'; 

// list numbers
const ListNumbers: FC<IListNumbers> = ({
  value: {
    items
  }
}) => {
  return (
    <div className="list-numbers">
      <ul className="list-numbers--list">
        {Array.isArray(items) && items.map((item: any, index: number) =>
          <li className="list-numbers--item" key={index}>
            <p className="number">
              <span className="number-text">{index+1}</span>
            </p>
            <p className="text">{item.text}</p>
          </li>)}
      </ul>
    </div>
  );
};

export default ListNumbers;