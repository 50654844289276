import React, { FC } from 'react';
import ReactPlayer from 'react-player';

import { IVideoPlayer } from './interfaces';

import './styles.scss';

// video player
const VideoPlayer: FC<IVideoPlayer> = ({
  value
}) => {
  return (
    <div className="video-player">
      <ReactPlayer url={value?.url} controls />
    </div>
  );
};

export default VideoPlayer;