import React, { FC } from 'react';

import AddressListItem from './AddressListItem';
import Content from '../../layout/Content';
import FormContact from '../Form/FormContact';

import './styles.scss';

// address list
const AddressList: FC<any> = ({
  value: {
    items
  }
}) => {
  return (
    <div className="address-list">
      <Content>
        <div className="address-list--container">
          <FormContact />

          <div className="address-list--container--list">
            {Array.isArray(items) && items.map((item: any, index: number) =>
              <AddressListItem {...item} key={index} />)}
          </div>
        </div>
      </Content>
    </div>
  );
};

export default AddressList;