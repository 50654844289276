import React, { FC } from 'react';

import TabsContent from './TabsContent';

import { ITabs } from './interfaces';

import './styles.scss';

// tabs
const Tabs: FC<ITabs> = ({
  value
}) => {
  return (
    <div className="tabs">
      <TabsContent
        categories={value.categories}
        items={value.items} />
    </div>
  );
};

export default Tabs;