import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { parse, format } from 'date-fns';

import './styles.scss';

import { INewsItemMin } from './interfaces';

// news item min
const NewsItemMin: FC<INewsItemMin> = ({
  date,
  intro,
  image,
  title,
  slug
}) => {
  const dataFormat = parse(format(new Date(date), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());

  // get date
  const getDate = useCallback(() =>
    format(dataFormat, 'dd.MM.yyyy'), [ dataFormat ]);

  // render
  return (
    <Link className="news-item-min" to={slug}>
      <div className="news-item-min--image">
        <img src={image.url} alt={title} />
      </div>

      <div className="news-item-min--info">
        <p className="date">{getDate()}</p>
        <p className="title">{title}</p>
        <p className="intro">{intro}</p>
      </div>
    </Link>
  );
};

export default NewsItemMin;