import React, { FC } from 'react';

import Content from '../../layout/Content';
import Submenu from '../Submenu';

import { ISummary } from './interfaces';

import './styles.scss';

// reports
const Summary: FC<ISummary> = ({
  value
}) => {
  // render
  return (
    <div className="summary">
      <Content>
        <div className="summary--container">
          <Submenu
            items={value.map((item, index: number) => { return { title: item.title, index }})} />

        </div>
      </Content>
    </div>
  );
};

export default Summary;