import React, { FC } from 'react';

import { ISelectItem } from './interfaces';

import './styles.scss';

// select item
const SelectItem: FC<ISelectItem> = ({
  item,
  onSelectOption,
  selectedOption
}) => {
  // render
  return (
    <button
      className="select-item-button"
      type="button"
      onClick={() => onSelectOption(item)}
      data-active={selectedOption?.value === item?.value}>
      <p className="text">{item?.label}</p>
    </button>
  );
};

export default SelectItem;