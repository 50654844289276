import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { IHeaderSubMenu } from './interfaces';

import './styles.scss';

// header menu
const HeaderSubMenu: FC<IHeaderSubMenu> = ({
  items
}) => {
  // render
  return (
    <div className="header-submenu">
      <ul className="header-submenu--list">
        {Array.isArray(items) && items.map((item: any, index: number) =>
          <li className="header-submenu--item" key={index}>
            <Link className="link-submenu" to={item?.to}>{item?.name}</Link>
          </li>)}
      </ul>
    </div>
  );
};

export default HeaderSubMenu;