import React, { FC } from 'react';

import { ITextRich } from './interfaces';

import './styles.scss';

// text rich
const TextRich: FC<ITextRich> = ({
  value: {
    html
  }
}) => {
  return (<p className="text-component" dangerouslySetInnerHTML={{ __html: html }} />);
};

export default TextRich;