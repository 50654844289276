import React, { createContext, FC, useCallback } from 'react';

import { INavContext, INavProvider } from './interfaces';

import useLoad from '../../hooks/useLoad';

// page context
const NavContext = createContext({} as INavContext);

// page provider
const NavProvider: FC<INavProvider> = ({
  children
}) => {
  const { error, data } = useLoad('routes.json');

  // find route
  const getRoute = useCallback((slug: string, routes: any[]) => {
    let page = false;

    for (let item of routes) {
      if (item?.slug === slug) {
        page = item;
      }
    }

    return page;
  }, []);

  // render
  return (
    <NavContext.Provider
      value={{
        getRoute,
        error,
        routes: data,
      }}>
        {children}
    </NavContext.Provider>
  );
};

export { NavContext, NavProvider };
export default NavProvider;