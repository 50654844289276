import React, { FC } from 'react';

import Content from '../../../layout/Content';
import WebdoorInternal from '../../WebdoorInternal';

import { IPageInterna } from './interfaces';

import './styles.scss';

// page interna
const PageInterna: FC<IPageInterna> = ({
  value,
  onLoadPage
}) => {
  return (
    <>
      <WebdoorInternal value={{ ...value?.webdoor }} />

      {value?.data &&
        <div className="page-interna">
          <Content>
            {onLoadPage(value)}
          </Content>
        </div>}
    </>
  );
};

export default PageInterna;