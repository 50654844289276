import React, { FC, memo } from 'react';

import { IPaginationButton } from './interfaces';

import './styles.scss';

// pagination button
const PaginationButton: FC<IPaginationButton> = ({
  attrs,
  active,
  index,
  setPage
}) => {
  // render
  return (
    <button
      {...attrs}
      className="pagination-button"
      data-active={active}
      onClick={() => setPage(index)}>
      {index + 1}
    </button>
  );
};

export default memo(PaginationButton);