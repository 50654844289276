import React, { FC } from 'react';

import { ISelectTitle } from './interfaces';

import './styles.scss';

// select title
const SelectTitle: FC<ISelectTitle> = ({
  active,
  defaultText,
  onToggle,
  selectedOption
}) => {
  // render
  return (
    <button
      className="select-title"
      data-active={active}
      type="button"
      onClick={() => onToggle(!active)}>
      <p className="select-title--title">
        <span className="icon icon-arrow"></span>
        <span
          className="text"
          data-empty={selectedOption?.value ? true : false}>
            {selectedOption?.label ? selectedOption?.label : defaultText}
        </span>
      </p>
    </button>
  );
};

export default SelectTitle;