import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { ISubmenu } from './interfaces';

import './styles.scss';

// reportsMenu
const Submenu: FC<ISubmenu> = ({
  items,
}) => {
  // render
  return (
    <div
      className="submenu-menu">
      <ul
        className="submenu-menu--list">
        {Array.isArray(items) && items.map((item: any, index: number) =>
          <li
            className="submenu-menu--item"
            key={index}>
            <NavLink
              className="submenu-menu--link"
              data-submenu={Array.isArray(item?.submenu)}
              to={item.to}>{item?.name}</NavLink>
          
            {Array.isArray(item.submenu) && <ul className="submenu-menu--item--list">
                {item.submenu.map((item: any, index: number) => 
                  <li className="submenu-menu--item--list--item" key={index}>
                    <NavLink className="submenu-menu--link--submenu" to={item.to}>{item.name}</NavLink>
                  </li>)}
              </ul>}
          </li>)}
      </ul>
    </div>
  );
};

export default Submenu;