import React, { FC } from 'react';

import Content from '../../layout/Content';

import { IWebdoorInternal } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// webdoor internal
const WebdoorInternal: FC<IWebdoorInternal> = ({
  value: {
    type,
    item,
    title,
    subtitle
  }
}) => {
  return (
    <div
      className="webdoor-internal"
      data-type={type}>
      <div className="webdoor-internal--bg">
        <img className="image" src={`${PUBLIC_URL}${item?.image}`} alt={item?.title} />
      </div>

      <div className="webdoor-internal--info">
        <Content>
          <div className="webdoor-internal--info--container">
            {subtitle && <p className="subtitle">{subtitle}</p>}
            <h1 className="title">{title}</h1>
          </div>
        </Content>
      </div>
    </div>
  );
};

export default WebdoorInternal;