import React, { FC } from 'react';

import { IReportsItem } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// reports item
const ReportsItem: FC<IReportsItem> = ({
  download,
  image,
  name,
  size,
  type
}) => {
  return (
    <div
      className="reports-item"
      data-type={type}>
      <div className="reports-item--image">
        <img src={`${PUBLIC_URL}${image}`} alt={name} />
      </div>

      <div className="reports-item--info">
        <p className="title">{name}</p>

        <a 
          className="download"
          href={download}
          download>
          <span className="icon icon-down"></span>
          <span className="text">PDF ({size})</span>
        </a>
      </div>
    </div>
  );
};

export default ReportsItem;