import React, { FC } from 'react';

import { IGalleryItem } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;

// gallery item
const GalleryItem: FC<IGalleryItem> = ({
  image
}) => {
  return (
    <div className="gallery-item">
      <img src={`${PUBLIC_URL}${image}`} alt="bg" />
    </div>
  );
};

export default GalleryItem;