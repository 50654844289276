import React, { FC, useState } from 'react';

import ListGridCollapseItem from './ListGridCollapseItem';

import { IListGridCollapse } from './interfaces';

import './styles.scss';

// list grid
const ListGridCollapse: FC<IListGridCollapse> = ({
  value: {
    items
  }
}) => {
  const [ active, setActive ] = useState<number | any>(null);

  // render
  return (
    <div className="list-grid-collapse">
      <ul className="list-grid-collapse--container">
        {items && items.map((item: any, index: number) =>
          <li
            className="list-grid-collapse--item"
            key={index}>
              <ListGridCollapseItem
                active={index === active}
                index={index}
                setActive={setActive}
                {...item} />
          </li>)}
      </ul>
    </div>
  );
};

export default ListGridCollapse;