import React, { memo, useCallback, useRef, useState, FC } from 'react';

import SelectItem from './SelectItem';
import SelectTitle from './SelectTitle';

import UseClickOutSide from '../../../hooks/useClickOutSide';

import { ISelect } from './interfaces';

import './styles.scss';

// select
const Select: FC<ISelect> = ({
  defaultText,
  handleChange,
  options,
  selectedOption,
  type,
  translate = true
}) => {
  // active
  const [ active, setActive ] = useState<boolean>(false);
  // element
  const element: any = useRef<any>(null);

  // click out
  UseClickOutSide(element, () => setActive(false));

  // on change
  const onChange = useCallback((item: any) => {
    setActive(false);
    handleChange(item);
  }, [ handleChange, setActive ]);

  // render
  return (
    <div
      className="select"
      data-active={active}
      data-type={type}
      ref={element}>
      <SelectTitle
        active={active}
        defaultText={defaultText}
        onToggle={setActive}
        selectedOption={selectedOption}
        translate={translate} />

      {options && <ul className="select--list">
        {options
          .filter((item: any) => item.label !== 'Selecione')
          .map((item: any, index: number) =>
          <li
            className="select--list--item"
            key={index}>
            <SelectItem
              item={item}
              onSelectOption={onChange}
              selectedOption={selectedOption} />
          </li>)}
      </ul>}
    </div>
  );
};

export default memo(Select);