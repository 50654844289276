import React, { Fragment, FC } from 'react';
import { Field } from 'formik';
import NumberFormat from 'react-number-format';

import Select from '../../Select';

import { IInputForm } from './interfaces';

import './styles.scss';

// Input
const InputForm: FC<IInputForm> = ({
  maxLength,
  required,
  empty,
  name,
  label,
  info,
  type,
  error,
  value = '',
  checked,
  setFieldValue,
  defaultText,
  subjects,
  onSetSubject,
  selectSubject,
  onChange
}) => {
  // get input type
  const getInputType = () => {
    switch (type) {
      case 'phone':
        return <NumberFormat 
          name={name}
          onChange={onChange}
          format="(##) ####-#####"
          mask=""
          value={value}
          required />;

      case 'check':
        return <>
          <Field
            name={name}
            type="checkbox" 
            required={required} />
            <span className="icon"></span>
          </>;
          
      case 'email':
        return <Field
          name={name}
          type="text" 
          required={required} />;

      case 'radio':
        return <>
          <input
            type="radio"
            id={name+value}
            name={name}
            checked={checked}
            onChange={() => setFieldValue(name, value)} 
            required={required} />
          <label className="radio-icon" htmlFor={name+value}></label>
        </>;

      case 'textarea':
        return <>
          <Field
            rows="12"
            name={name}
            component="textarea"
            required={required} />
          <label className="info">{info}</label>
        </>;

      case 'select':
        return <div className="input-select">
          <Select
            defaultText={defaultText}
            options={subjects}
            handleChange={onSetSubject}
            selectedOption={selectSubject} />

          <input name={name} type="hidden" required={true} />
        </div>;

      case 'text':
      default:
        return <Field
          type="text"
          name={name}
          maxLength={maxLength}
          required={required} />;
    }
  };

  // render
  return (
    <label
      className="input-label"
      data-empty={!empty}
      data-error={error}
      data-type={type}
      data-required={required}>
      <label className="label">{label}</label>
      {getInputType()}
    </label>
  );
};

export default InputForm;