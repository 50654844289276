import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Content from '../../../layout/Content';
import WebdoorControls from '../WebdoorControls';

import { IWebdoorItem } from './interfaces';

import './styles.scss';

// webdoor item
const WebdoorItem: FC<IWebdoorItem> = ({
  active,
  dir,
  drag,
  item,
  last,
  onPrevNext,
}) => {
  // on drag
  const onDrag = useCallback(() => {
    if (typeof drag === 'function') return drag();

    return {};
  }, [drag]);

  // render
  return (
    <div
      {...onDrag()}
      className="webdoor-item"
      data-active={active}
      data-last={last}
      data-dir={dir}>
      <Link
        className="link"
        to={item?.to}>
        <img src={item?.image} alt={item?.title} />
      </Link>

      <Content>
        <div className="webdoor-item--content">
          <span className="description">{item.description}</span>
          <span className="title">{item.title}</span>

          <WebdoorControls
            onPrevNext={onPrevNext} />
        </div>
      </Content>
    </div>
  );
};

export default WebdoorItem;