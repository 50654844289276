import React, { FC } from 'react';

import { IListBlockItem } from './interfaces';

import './styles.scss';

// list block item
const ListBlockItem: FC<IListBlockItem> = ({
  description,
  footer,
  index,
  list,
  title,
}) => {
  return (
    <div className="list-block-item">
      <p className="number">{index}</p>

      <div className="list-block-item--container">
        <p className="title">{title}</p>

        {description && 
          <p className="description" dangerouslySetInnerHTML={{ __html: description }} />}
        
        {Array.isArray(list) && <ul className="list-block-item--list">
            {list.map((item: string, index: number) =>
              <li className="list-block-item--list--item" key={index}>
                <p dangerouslySetInnerHTML={{ __html: item }} />
              </li>)}
          </ul>}

        {footer && <p className="footer-description" dangerouslySetInnerHTML={{ __html: footer }} />}
      </div>
    </div>
  );
};

export default ListBlockItem;