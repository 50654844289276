import React, { createContext, FC } from 'react';

import { INewsContext, INewsProvider } from './interfaces';

// components
import useLoad from '../../hooks/useLoad';

// news context
const NewsContext = createContext({} as INewsContext);

// news provider
const NewsProvider: FC<INewsProvider> = ({ children }) => {
  // data
  const { data } = useLoad('news.json');
  
  // render
  return (
    <NewsContext.Provider value={{
      data,
    }}>{children}
    </NewsContext.Provider>
  );
};

export { NewsContext, NewsProvider };
export default NewsProvider;