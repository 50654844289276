import React, { FC, useCallback } from 'react';
import { useGesture } from 'react-use-gesture';
import clamp from 'lodash/clamp';

import GalleryVideosControls from '../GalleryVideosControls';
import Content from '../../../layout/Content';
import SliderBase from '../../Slider/Base';
import SubTitle from '../../Typograph/SubTitle';
import GalleryVideosItem from '../GalleryVideosItem';

import { IGalleryVideosContent } from './interfaces';

import './styles.scss';

// webdoor content
const GalleryVideosContent: FC<IGalleryVideosContent> = ({
  current,
  items,
  direction,
  last,
  onPrevNext,
  title,
  setCurrent
}) => {
  // drag on gesture
  const dragOnGesture = useCallback(({
    down,
    movement: [mx],
    direction: [xDir],
    distance,
    cancel
  }: any) => {
    if (down && distance > 15) {
      const value = clamp(current + (xDir > 0 ? 1 : -1), 0, items.length - 1);
      cancel(setCurrent(value));
    }
  }, [current, items, setCurrent]);

  // drag
  const drag = useGesture({
    onDrag: dragOnGesture
  });

  // render
  return (
    <Content>
      <SubTitle text={title} />

      <div
        className="gallery-videos-content">
        {items && items.map((item: any, index: number) => (
          <GalleryVideosItem
            active={current === index}
            drag={drag}
            dir={direction}
            item={item}
            next={current === (items.length - 1) ? index === 0 : (current + 1) === index}
            prev={current === 0 ? (items.length - 1) === index : (current - 1) === index}
            key={index} />))}
      </div>

      <GalleryVideosControls
        onPrevNext={onPrevNext} />
    </Content>
  );
};

export default SliderBase(GalleryVideosContent);