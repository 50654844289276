import React, { FC, useState } from 'react';

import SliderBase from '../../Slider/Base';
import TabsBody from '../TabsBody';
import TabsHeader from '../TabsHeader';

import { ITabsContent } from './interfaces';

import './styles.scss';

// tabs content
const TabsContent: FC<ITabsContent> = ({
  current,
  categories,
  items,
  setCurrent
}) => {
  const [ filter, setFilter ]: any = useState<any>({ value: '', label: '' });

  // render
  return (
    <div className="tabs-content">
      <TabsHeader
        categories={categories}
        current={current}
        items={items}
        setCurrent={setCurrent}
        filter={filter}
        setFilter={setFilter} />

      <TabsBody
        current={current}
        filter={filter}
        value={items} />
    </div>
  );
};

export default SliderBase(TabsContent);