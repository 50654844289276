import React, { FC } from 'react';

import { ISubtitle } from './interfaces';

import './styles.scss';

// subtitle
const SubTitle: FC<ISubtitle> = ({
  text,
  type,
  value
}) => {
  return (
    <div className="subtitle" data-type={type}>
      <p className="text">{text || value?.text}</p>
    </div>
  );
};

export default SubTitle;