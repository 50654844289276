import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Title from '../../Typograph/Title';

import './styles.scss';

// info graphic item
const InfographicItem: FC<any> = ({
  description,
  image: {
    url = '',
  },
  to,
  title
}) => {
  // render
  return (
    <div className="infographic-item">
      <Title text={title} />

      <div className="infographic-item--image">
        <img src={url} alt={title} />
      </div>

      <Link className="infographic-item--description" to={to}>
        <p className="text">{description}</p>

        <p className="link">
          <span className="icon icon-arrow"></span>
        </p>
      </Link>
    </div>
  );
};

export default InfographicItem;