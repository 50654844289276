import React, { FC } from 'react';

import { IListGridFileItem } from './interfaces';

import './styles.scss';

// list grid file item
const ListGridFileItem: FC<IListGridFileItem> = ({
  title,
  items
}) => {
  return (
    <div className="list-grid-file-item">
      <p className="title">{title}</p>

      {Array.isArray(items) && items.map((item: any, index: number) =>
        <a className="link" download href={item.url} key={index}>
          <span className="icon icon-down"></span>
          <span className="text">{item.name}</span>
        </a>)}
    </div>
  );
};

export default ListGridFileItem;