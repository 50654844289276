import React, { FC } from 'react';

import WebdoorContent from './WebdoorContent';

import { IWebdoor } from './interfaces';

import './styles.scss';

// webdoor
const Webdoor: FC<IWebdoor> = ({
  value: {
    items
  }
}) => {
  // render
  return (
    <div className="webdoor">
      <WebdoorContent
        items={items} />
    </div>
  );
};

export default Webdoor;