import React, { FC } from 'react';

import { IText } from './interfaces';

import './styles.scss';

// text
const Text: FC<IText> = ({
  value: {
    text,
    type
  }
}) => {
  return (<p className="text-component" data-type={type}>{text}</p>);
};

export default Text;