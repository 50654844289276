import React, { FC } from 'react';

import PaginationButton from '../PaginationButton';
import PaginationControl from '../PaginationControl';

import { IPaginationFooter } from './interfaces';

import './styles.scss';

// pagination footer
const PaginationFooter: FC<IPaginationFooter> = ({
  current,
  onPrevNext,
  total,
  setPage
}) => {
  const items = Array.from({length: total }, () => 0);

  // render
  return (
    <div className="pagination-footer">
      <PaginationControl
        disable={current === 0}
        type="prev"
        onPrevNext={onPrevNext} />

      <ul className="pagination-footer--list">
        {Array.isArray(items) && items.map((item: any, index: number) =>
          <li key={index}>
            <PaginationButton
              active={current === index}
              index={index}
              setPage={setPage} />
          </li>)}
      </ul>

      <PaginationControl
        disable={current >= (total - 1)}
        type="next"
        onPrevNext={onPrevNext} />
    </div>
  );
};

export default PaginationFooter;