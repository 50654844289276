import React, { FC, useContext } from 'react';
import Slider from 'react-slick';

import Content from '../../../layout/Content';
import { NewsContext } from '../../../providers/NewsProvider';
import SubTitle from '../../Typograph/SubTitle';

import ListNewsControl from '../ListNewsControl';
import ListNewsItem from '../ListNewsItem';

import { IListNewsContent } from './interfaces';

import { settings } from './settings';

// list news content
const ListNewsContent: FC<IListNewsContent> = ({
  getDate,
  limit = 8
}) => {
  const { data } = useContext(NewsContext);

  // render
  return (
    <>
      <Content>
        <SubTitle text="feed de atualizações" />

        {Array.isArray(data) &&
          <Slider
            {...settings}
            className="slider"
            nextArrow={<ListNewsControl type="right" />}
            prevArrow={<ListNewsControl type="left" />}>
            {Array.isArray(data) &&
              data.slice(0, limit).map((item: any, index: number) =>
              <ListNewsItem
                {...item}
                getDate={getDate}
                key={index} />)}
          </Slider>}
      </Content>
    </>
  );
};

export default ListNewsContent;