import React, { FC } from 'react';

import { IAddressListCard } from './interfaces';

import './styles.scss';

// address list card
const AddressListCard: FC<IAddressListCard> = ({
  address,
  phone,
  print,
  title,
}) => {
  return (
    <div className="address-list-card">
      <p className="title">{title}</p>

      {address && <p className="address-list-card--item">
          <span className="icon icon-picker"></span>
          <span className="text">{address}</span>
        </p>}

      {phone && <p className="address-list-card--item">
          <span className="icon icon-phone"></span>
          <span className="text">{phone}</span>
        </p>}
      
      {print && <p className="address-list-card--item">
        <span className="icon icon-print"></span>
        <span className="text">{print}</span>
      </p>}
    </div>
  );
};

export default AddressListCard;