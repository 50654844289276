import AddressList from "./AddressList";
import Banner from "./Banner";
import GalleryVideos from "./GalleryVideos";
import Gallery from "./Gallery";
import Highlights from "./Highlights";
import InfoGraphic from "./Infographic";
import Image from './Image';
import Intro from "./Intro";
import News from "./News";
import NewDetails from "./NewDetails";
import ListBlock from "./List/ListBlock";
import ListBlockImage from "./List/ListBlockImage";
import ListNews from "./ListNews";
import ListGrid from "./List/ListGrid";
import ListGridCollapse from "./List/ListGridCollapse";
import ListGridFile from "./List/ListGridFile";
import ListGridIcon from "./List/ListGridIcon";
import ListGridLinks from "./List/ListGridLinks";
import ListNumbers from "./List/ListNumbers";
import ListIcons from "./List/ListIcons";
import Reports from "./Reports";
import Summary from "./Summary";
import Tabs from "./Tabs";
import Text from './Text';
import TextRich from "./TextRich";
import Subtitle from './Typograph/SubTitle';
import Webdoor from "./Webdoor"
import WebdoorInternal from "./WebdoorInternal";
import VideoPlayer from "./VideoPlayer";

export const TYPE_COMPONENTS = [{
  componentElement: AddressList,
  type: "address-list"
}, {
  componentElement: Banner,
  type: "banner"
}, {
  componentElement: Gallery,
  type: "gallery"
}, {
  componentElement: GalleryVideos,
  type: "gallery_videos"
}, {
  componentElement: Highlights,
  type: "highlights"
}, {
  componentElement: Image,
  type: "image"
}, {
  componentElement: InfoGraphic,
  type: "infographic"
}, {
  componentElement: Intro,
  type: "intro"
}, {
  componentElement: News,
  type: "news"
}, {
  componentElement: NewDetails,
  type: "new-details"
},{
  componentElement: ListBlock,
  type: "list-block"
}, {
  componentElement: ListGridIcon,
  type: "list-grid-icons"
}, {
  componentElement: ListBlockImage,
  type: "list-block-image"
}, {
  componentElement: ListGrid,
  type: "list-grid"
}, {
  componentElement: ListGridCollapse,
  type: "list-grid-collapse"
}, {
  componentElement: ListGridFile,
  type: "list-grid-file"
}, {
  componentElement: ListGridLinks,
  type: "list-grid-links"
}, {
  componentElement: ListIcons,
  type: "list-icons"
}, {
  componentElement: ListNumbers,
  type: "list-numbers"
}, {
  componentElement: ListNews,
  type: "list_news"
}, {
  componentElement: Reports,
  type: "reports"
}, {
  componentElement: Summary,
  type: "summary"
}, {
  componentElement: Tabs,
  type: "tabs"
}, {
  componentElement: TextRich, 
  type: "text-rich"
}, {
  componentElement: Text,
  type: "text"
}, {
  componentElement: Subtitle,
  type: "title"
}, {
  componentElement: Webdoor,
  type: "webdoor",
}, {
  componentElement: WebdoorInternal,
  type: "webdoor-internal",
}, {
  componentElement: VideoPlayer,
  type: "video"
}];