import React, { FC, useContext, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';
import Page from '../../components/Page';
import PageNew from '../../components/PageNew';

import { NavContext } from '../../providers/NavProvider';
import PageProvider from '../../providers/PageProvider';

// Main
const Main: FC<any> = ({
  location
}) => {
  const { routes = [], getRoute } = useContext(NavContext);
  const route = getRoute(location.pathname, routes);

  // use effect
  useEffect(() => {
    if (location)
      window.scroll(0, 0);
  }, [location]);

  // render
  return (
    <>
      <Header />

      <Switch>
        <Route
          path='/noticias/:slug'
          component={() => <PageNew />} />

        {routes && <PageProvider
          url={route?.url}>
            <Route
              exact
              path='/'
              component={() => <Page route={route} />} />

            <Route
              path='/:slug'
              component={() => <Page route={route} />} />
          </PageProvider>}

        
      </Switch>

      <Footer />
    </>
  );
};

export default withRouter(Main);