import React, { FC } from 'react';

import { IListGridLinks } from './interfaces';
import ListGridLinksItem from './ListGridLinksItem';

import './styles.scss';

// list grid links
const ListGridLinks: FC<IListGridLinks> = ({
  value: {
    items
  }
}) => {
  return (
    <div className="list-grid-links">
      <ul className="list-grid-links--list">
        {items && items.map((item: any, index: number) =>
          <li className="list-grid-links--item" key={index}>
            <ListGridLinksItem {...item} />
          </li>)}
      </ul>
    </div>
  );
};

export default ListGridLinks;