import React, { FC } from 'react';

import { ITitle } from './interfaces';

import './styles.scss';

// title
const Title: FC<ITitle> = ({
  text
}) => {
  // render
  return (
    <div className="title">
      <span className="text">{text}</span>
    </div>
  );
};

export default Title;