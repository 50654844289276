import React from 'react';

// Pagination HOC
export default function PaginationArray(Component: any) {
  const base: any = class extends React.Component {
    state: any;

    // constructor
    constructor (props: any) {
      super(props);

      this.state = {
        offset: 0,
        data: props.items,
        perPage: props.perPage,
        currentPage: 0
      };

      this.setPage = this.setPage.bind(this);
    }

    // component
    componentDidMount() {
      this.getData(this.state.data, this.state.perPage);
    }

    // getData
    getData = (items: any[], page_number: number) => {
      if (!items || !items.length) return this.state.data;

      return items.slice(this.state.offset, page_number * this.state.perPage);
    }
    
    // set page
    setPage = (page: number) => {
      const offset = page * this.state.perPage;

      this.setState({
        currentPage: page,
        offset: offset
      });
    }

    // render
    render () {
      return (
        <Component
          {...this.state}
          {...this.props}
          getData={this.getData}
          setPage={this.setPage}
        />
      );
    }
  };

  base.displayName = `PaginationArray(${Component.displayName || Component.name})`;

  return base;
};