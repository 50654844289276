import React, { FC } from 'react';
import Slider from 'react-slick';

import GalleryControl from './GalleryControl';
import GalleryItem from './GalleryItem';

import { IGallery } from './interfaces';

import { settings } from './settings';

import './styles.scss';

// list news content
const Gallery: FC<IGallery> = ({
  value: {
    items
  }
}) => {
  // render
  return (
    <div className="gallery">
      <div className="gallery--container">
        {Array.isArray(items) &&
          <Slider
            {...settings}
            className="slider"
            nextArrow={<GalleryControl type="right" />}
            prevArrow={<GalleryControl type="left" />}>
            {Array.isArray(items) && items.map((item: any, index: number) =>
              <GalleryItem
                {...item}
                key={index} />)}
          </Slider>}
      </div>
    </div>
  );
};

export default Gallery;