import React, { FC } from 'react';

import { IGalleryControl } from './interfaces';

import './styles.scss';

// list news control
const GalleryControl: FC<IGalleryControl> = ({
  onClick,
  type,
}) => {
  // render
  return (
    <button
      className={`gallery-control ${type}`}
      onClick={onClick}>
      <span className="icon icon-arrow-strong"></span>
    </button>
  );
};

export default GalleryControl;