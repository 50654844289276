import React, { FC } from 'react';

import Content from '../../layout/Content';
import InfographicItem from './InfographicItem';
import NewsList from '../News/NewsList';

import { IInfoGraphic } from './interfaces';

import './styles.scss';

// info graphic
const InfoGraphic: FC<IInfoGraphic> = ({
  value
}) => {
  // render
  return (
    <div className="infographic">
      <Content>
        <div className="infographic--content">
          <NewsList />
         
          {Array.isArray(value.items) && value.items.map((item: any, index: number) =>
            <InfographicItem {...item} key={index} />)}
        </div>
      </Content>
    </div>
  );
};

export default InfoGraphic;